.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
}

@media only screen and (max-width: 768px) {
    .page-header-mobile{
        min-height: 65vh !important;
    }
    .bg-gray-sm{
        background-color: rgba(211, 239, 218, 0.3);
    }
    .padding-left-40-sm{
        padding-left: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .padding-left-100-lg{
        padding-left: 100px;
    }
    .margin-top-70-lg{
        margin-top: 70px;
    }
    .padding-top-30-lg{
        padding-top: 30px;
    }
}

.about-us-section{
    background-color: white;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    padding-top: 90px;
    font-family: 'ProductSansRegular';
}

.problem-solve-section{
    background-color: white;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    padding-top: 90px;
    font-family: 'ProductSansRegular';
}

.fade-in-section {
    font-family: 'ProductSansRegular';
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
    will-change: opacity, visibility,transform;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.why-citigen-products{
    margin-top: "24px" !important;
    margin-bottom: "24px" !important;
    font-size: "24px";
    font-weight: "500";
    color: "#808080";
    &:hover{
        color:"#286700";
    }
}

.carousel-item-padding-40-px{
    padding-left: 20px;
    padding-right: 20px;
}

.react-multiple-carousel__arrow--left{
    left:calc(4% + 1px)
}

.react-multiple-carousel__arrow--right{
    right:calc(4% + 1px)
}

.custom-dot-list-style button{
    background: "#FEC325";
}

.filter-green{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.filter-yellow{
    filter: invert(92%) sepia(14%) saturate(6176%) hue-rotate(336deg) brightness(103%) contrast(102%);
}

.color-green{
    color: #286700 !important;
}

.page-header-blog {
    min-height: 22vh !important;
}

.list-mid {
    text-align: right; 
}
  
.list-mid .logo {
    /*padding: 15px;*/
    max-width: 180px;
    margin: -80px auto 0;
    display: inline-block;
}

.set-margin-top-bottom{
    margin-top: 0px !important;
    margin-bottom: 10px !important;

}

.section-blog-content{
    padding-bottom: 10px;
}

.set-aligment-min-read {
    padding: 7px 18px;
    font-weight: 400;
}

.border-2px-green{
    border: 2px solid rgb(40,103,0) !important;
}