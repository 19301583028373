.navbar-semi-transparent{
    background: rgba(0, 0, 0, 0.4);
}

.navbar-visible{
    top:0px;
}

.navbar-hidden{
    top: -100px;
}