@font-face {
    font-family: 'ProductSansRegular';
    src: local('ProductSansRegular'), url(./assets/fonts/ProductSansRegular.ttf) format('truetype');
}

body{
    font-family: 'ProductSansRegular';
}

html {
    scroll-behavior: smooth;
}