.about-us-section{
    background-color: white;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    padding-top: 90px;
    font-family: 'ProductSansRegular';
}

.mission-vission{
    background-color: white;
    min-height: 50vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    padding-top: 90px;
    font-family: 'ProductSansRegular';
}

.color-white{
    color: white;
}

.page-header-portfolio{
    min-height: 24vh !important;
}