.text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(211, 239, 218, 0.3);
}

.fill-current {
   fill: currentColor;
}

.bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(211, 239, 218, 0.3);
}

.overflow-hidden {
    overflow: hidden;
}

.bottom-auto {
    bottom: auto;
}

.top-0 {
    top: 0;
}
  
.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.bottom-0 {
    bottom: 0;
}

.w-full {
    width: 100%;
}

.absolute {
    position: absolute;
}

.pointer-events-none {
    pointer-events: none;
}

.-mt-20 {
    margin-top: -5rem;
}

.h-20 {
    height: 5rem;
}

.relative {
    position: relative;
}

.pt-8 {
    padding-top: 2rem;
}
  
.pb-6 {
    padding-bottom: 1.5rem;
}

.footer-nav{
    font-size: 18px;
    color:black;
    font-weight: 400;
}

.footer-nav-item{
    font-size: "18px";
    font-weight: "400";
    font-family: "ProductSansRegular";
}