.btn-twitter{
    color: $social-twitter !important;

    &:hover,
    &:focus,
    &:active{
        color: $social-twitter !important;
    }
}

.btn-facebook{
    color: $social-facebook !important;

    &:hover,
    &:focus,
    &:active{
        color: $social-facebook !important;
    }
}

.btn-google{
    color: $social-google !important;

    &:hover,
    &:focus,
    &:active{
        color: $social-google !important;
    }
}

.btn-linkedin{
    color: $social-linkedin !important;

    &:hover,
    &:focus,
    &:active{
        color: $social-linkedin !important;
    }
}
.btn-twitter-bg{
    background-color: $social-twitter !important;
    border-color: $social-twitter !important;
    &:hover{
        background-color: darken($social-twitter, 5%) !important;
        border-color: darken($social-twitter, 5%) !important;
    }
}
.btn-facebook-bg{
    background-color: $social-facebook !important;
    border-color: $social-facebook !important;
    &:hover{
        background-color: darken($social-facebook, 5%) !important;
        border-color: darken($social-facebook, 5%) !important;
    }
}
.btn-instagram-bg{
    background-color: #e4405f !important;
    border-color: #e4405f !important;
    &:hover{
        background-color: darken(#e4405f, 5%) !important;
        border-color: darken(#e4405f, 5%) !important;
    }
}
.btn-linkedin-bg{
    background-color: #0e76a8 !important;
    border-color: #0e76a8 !important;
    &:hover{
        background-color: darken(#0e76a8, 5%) !important;
        border-color: darken(#0e76a8, 5%) !important;
    }
}
